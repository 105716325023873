/*
::
:: Theme Name: Petrol - Industry and Manufacturing HTML5 Template
:: Email: Nourramadan144@gmail.com
:: Author URI: https://themeforest.net/user/ar-coder
:: Author: ar-coder
:: Version: 1.0
:: 
*/

/* :: Body */
body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 25px;
  color: #222222;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

::-moz-selection {
  background-color: #E11D07;
  color: #FFF;
  text-shadow: none;
}

::selection {
  background-color: #E11D07;
  color: #FFF;
  text-shadow: none;
}

a,
a:hover,
a:focus,
button:focus {
  text-decoration: none;
  color: #FFF;
  cursor: pointer;
}