/* :: Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&family=Inter:wght@400;500;600;700&family=Play:wght@400;500;600;700&display=swap');

/*
01 :: Reset Page Css
02 :: Style Page Css
    01-  :: Loading
    02-  :: Navbar
    03-  :: Search Box
    04-  :: Menu Box
    05-  :: Header(Offer)
    06-  :: Features
    07-  :: About Us
    08-  :: Services
    09-  :: Provide
    10-  :: Work
    11-  :: Contact
    12-  :: Team
    13-  :: Quote
    14-  :: Sponsors
    15-  :: Testimonial
    16-  :: News
    17-  :: Footer
    18-  :: Scroll UP
*/

:root {
  --color1: #9B9B9B;
  --color2: #E11D07;
  --color3: #ffffff;
  --color4: #222222;
  --color5: #FECDFF;
  --color6: #F1F1F1;
  --color7: #999;
  --color8: #F9F9F9;
  --color9: #E9E9E9;
  --color10: #A5A5A5;
  --color11: #EAEAEA;
  --color12: #6c6c6c;
}

.visible {
  display: block !important;
}

/* :: All Transition */
* {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: none;
}

/* :: Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  text-transform: capitalize;
}

p {
  color: var(--color1);
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  line-height: 25px;
}

/* :: Display Table */
.display-table {
  width: 100%;
  height: 100%;
  display: table;
  position: relative;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
  min-height: 100%;
}

/* :: My Classes */
.py-100 {
  padding: 100px 0;
}

.py-100-70 {
  padding: 100px 0 70px 0;
}

.p-0 {
  padding: 0;
}

.ml-30 {
  margin-left: 30px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.bg-section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 34, 34, 0.35);
}

.btn-1 {
  text-transform: capitalize;
  position: relative;
  text-align: center;
  z-index: 1;
  font-size: 14px;
  font-weight: 600;
  min-width: 180px;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
  letter-spacing: 1px;
  border: none;
  border-radius: 3px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: var(--color2);
  color: var(--color3);
  display: inline-block;
  cursor: pointer;
}

.btn-1:hover {
  color: var(--color2);
}

.btn-1:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--color3);
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: bottom center;
  -ms-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.btn-1:hover:after {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
}

.btn-2 {
  background-color: var(--color3);
  color: var(--color2);
}

.btn-2:hover {
  color: var(--color3);
}

.btn-2:after {
  background-color: var(--color2);
}

.btn-3:hover {
  color: var(--color3);
}

.btn-3:after {
  background-color: var(--color4);
}

.btn-4 {
  background-color: var(--color4);
  color: var(--color3);
}

.btn-4:hover {
  color: var(--color3);
}

.btn-4:after {
  background-color: var(--color2);
}

/* :: Section Title */
.sec-title {
  margin-bottom: 50px;
}

.sec-title h2 {
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-size: 15px;
  line-height: 1;
  margin-bottom: 21px;
  font-weight: 700;
  color: var(--color2);
  font-family: 'Roboto', sans-serif;
  position: relative;
  display: inline-block;
}

.sec-title h3 {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 48px;
  line-height: 1.1;
  letter-spacing: 0.3px;
  position: relative;
}

.sec-title p {
  /* text-transform: capitalize; */
  font-weight: 400;
  line-height: 27px;
  font-size: 16px;
  margin-bottom: 0;
  position: relative;
}

#services-page .sec-title h2.lng-services_services_h2 {
  z-index: 1;
}

/* :: Section Title Home 2 */
.sec-title-home-2 h2 {
  padding-bottom: 3px;
  z-index: 1;
}

.sec-title-home-2 h2:after {
  content: '';
  background-color: var(--color2);
  position: absolute;
  height: 2px;
  width: 5000px;
  bottom: 0;
  right: 0;
}

/* :: Section Title Home 3 */
.sec-title h2:after {
  content: '';
  background-color: var(--color2);
  position: absolute;
  height: 2px;
  width: 5000px;
  top: calc(50% - 2px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: calc(100% + 10px);
}

#about-us-page .sec-title h2:after,
#services-page .sec-title h2:after {
  z-index: -1;
}

#services-page .about-us-home-2 {
  padding-top: 0;
}

.about-us-home-0 .sec-title h2:after {
  z-index: -1;
}

.about-us ul li {
  cursor: pointer;
}

/* :: Loading */
.loading {
  position: fixed;
  background-color: var(--color3);
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  -webkit-transition: unset;
  -o-transition: unset;
  transition: unset;
}

.loading .loading-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.loading .loading-box .ar {
  margin: 0 10px;
  width: 24px;
  height: 6px;
  border-radius: 8px;
  background: var(--color2) !important;
  -webkit-box-shadow: 0 0 10px 0 var(--color5);
  box-shadow: 0 0 10px 0 var(--color5);
}

.loading .loading-box .uno {
  margin-right: -18px;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.loading .loading-box .dos {
  -webkit-transform-origin: center right;
  -ms-transform-origin: center right;
  transform-origin: center right;
  -webkit-animation: spin2 3s linear infinite;
  animation: spin2 3s linear infinite;
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.loading .loading-box .tres {
  -webkit-transform-origin: center right;
  -ms-transform-origin: center right;
  transform-origin: center right;
  -webkit-animation: spin3 3s linear infinite;
  animation: spin3 3s linear infinite;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.loading .loading-box .cuatro {
  -webkit-transform-origin: center right;
  -ms-transform-origin: center right;
  transform-origin: center right;
  -webkit-animation: spin4 3s linear infinite;
  animation: spin4 3s linear infinite;
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  30% {
    -webkit-transform: rotate(370deg);
    transform: rotate(370deg);
  }

  35% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  30% {
    -webkit-transform: rotate(370deg);
    transform: rotate(370deg);
  }

  35% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  30% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  35% {
    -webkit-transform: rotate(-190deg);
    transform: rotate(-190deg);
  }

  40% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  78% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  95% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }

  98% {
    -webkit-transform: rotate(-370deg);
    transform: rotate(-370deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes spin2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  30% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  35% {
    -webkit-transform: rotate(-190deg);
    transform: rotate(-190deg);
  }

  40% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  78% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  95% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }

  98% {
    -webkit-transform: rotate(-370deg);
    transform: rotate(-370deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  27% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  40% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  45% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  62% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  80% {
    -webkit-transform: rotate(370deg);
    transform: rotate(370deg);
  }

  85% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  27% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  40% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  45% {
    -webkit-transform: rotate(190deg);
    transform: rotate(190deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  62% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  80% {
    -webkit-transform: rotate(370deg);
    transform: rotate(370deg);
  }

  85% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  38% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  60% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }

  65% {
    -webkit-transform: rotate(-370deg);
    transform: rotate(-370deg);
  }

  75% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes spin4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  38% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  60% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }

  65% {
    -webkit-transform: rotate(-370deg);
    transform: rotate(-370deg);
  }

  75% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

/* :: Navbar */
.nav-bar {
  border-bottom: 1px solid rgba(255, 255, 255, .15);
}

.nav-bar.active {
  background-color: var(--color3) !important;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, .07);
  box-shadow: 0 3px 4px rgba(0, 0, 0, .07);
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  border-bottom: none;
}

.nav-bar.active-white {
  background-color: var(--color3) !important;
}

.nav-bar .logo .logo-nav {
  display: block;
}

.nav-bar .logo .logo-nav img {
  width: 130px;
}

.nav-bar .logo .logo-nav span {
  color: var(--color3);
}

.logo .logo-nav svg {
  color: var(--color2);
  font-size: 1.7rem;
  line-height: 1.4;
}

.logo .logo-nav span {
  font-size: 2rem;
  font-weight: 700;
  margin-left: 10px;
  line-height: 1.4;
}

.nav-bar.active .logo .logo-nav span {
  color: var(--color4) !important;
}

.nav-bar .logo .logo-nav img.two,
.nav-bar.active .logo .logo-nav img.one {
  display: none;
}

.nav-bar.active .logo .logo-nav img.two {
  display: block;
}

.nav-bar.active .info-nav .btn-1:after {
  background-color: var(--color4) !important;
}

.nav-bar .lang {
  margin: auto 0 auto 25px;
}

.nav-bar .lang .second-btn {
  margin-left: 7px;
}

.nav-bar .lang button {
  padding: 1px 6px;
  border: none;
  background-color: var(--color3);
  border-radius: 3px;
  color: var(--color2);
  font-size: 15px;
  transition: 0.3s;
  text-transform: capitalize;
}

.nav-bar .lang button:hover {
  color: var(--color4);
}

.nav-bar .lang button.active {
  background-color: var(--color2);
  border-radius: 3px;
  color: var(--color3);
}

.nav-bar .nav-bar-link>ul>li {
  display: inline-block;
  position: relative;
}

.nav-bar .nav-bar-link ul li a,
.menu-item-faq {
  color: var(--color3);
  font-size: 14px;
  font-weight: 700;
  padding: 30px 0;
  margin: 0 20px 0 0px;
  position: relative;
  display: block;
  text-transform: capitalize;
}

.nav-bar .nav-bar-link ul li a:before,
.menu-item-faq:before {
  content: '';
  background-color: var(--color2);
  position: absolute;
  width: 0%;
  height: 3px;
  left: 0;
  bottom: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.menu-item-faq {
  cursor: pointer;
}

.nav-bar .nav-bar-link ul li .active:before {
  width: 100%;
}

.nav-bar.active .nav-bar-link ul li a,
.menu-item-faq {
  color: var(--color4);
}

.nav-bar.active .nav-bar-link>ul ul li a {
  color: var(--color1);
}

.nav-bar .nav-bar-link ul li a:hover,
.nav-bar .nav-bar-link ul li a.active,
.menu-item-faq:hover,
.menu-item-faq.active,
.nav-bar.active .nav-bar-link>ul ul li:hover a {
  color: var(--color2);
}

.nav-bar .nav-bar-link>ul ul {
  position: absolute;
  z-index: 2;
  background-color: var(--color3);
  border-radius: 2px;
  padding: 25px 0;
  top: 100%;
  left: 0;
  min-width: 240px;
  -webkit-box-shadow: 0 8px 80px rgba(40, 40, 40, 0.08);
  box-shadow: 0 8px 80px rgba(40, 40, 40, 0.08);
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-bar .nav-bar-link>ul li:hover>ul {
  -webkit-transform: translateY(00px);
  -ms-transform: translateY(00px);
  transform: translateY(00px);
  opacity: 1;
  visibility: visible;
}

.nav-bar .nav-bar-link>ul ul li {
  position: relative;
  padding: 0 30px 0 40px;
}

.nav-bar .nav-bar-link>ul ul li a {
  color: var(--color1);
  font-size: 14px;
  line-height: 40px;
  border-bottom: 1px solid rgb(155 155 155 / 30%);
  padding: 0;
  width: 100%;
  position: relative;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
}

.nav-bar .nav-bar-link>ul ul li:hover a {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.nav-bar .nav-bar-link>ul ul ul {
  left: 100%;
  top: 0;
}

.nav-bar .nav-bar-link>ul ul ul li a {
  -webkit-transform: translateX(0px) !important;
  -ms-transform: translateX(0px) !important;
  transform: translateX(0px) !important;
  color: var(--color1) !important;
}

.nav-bar .nav-bar-link>ul ul ul li:hover a {
  -webkit-transform: translateX(10px) !important;
  -ms-transform: translateX(10px) !important;
  transform: translateX(10px) !important;
  color: var(--color2) !important;
}

.nav-bar .nav-bar-link .level-1:last-of-type li a,
.menu-item-faq {
  padding-right: 0;
}

.nav-bar .nav-bar-link .level-1:last-of-type li:last-of-type a {
  margin: 0;
  border-bottom: 0;
}

.nav-bar .info-nav .item {
  display: inline-block;
  margin-right: 20px;
}

.nav-bar-home-3 .info-nav li.item {
  padding: 28px 0;
}

.nav-bar .info-nav .item:last-of-type {
  margin-right: 0px;
}

.nav-bar .info-nav .item i.icon {
  color: var(--color3);
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
}

.nav-bar .info-nav .item i.icon:hover,
.nav-bar.active .info-nav .item i.icon:hover {
  color: var(--color2);
}

.nav-bar.active .info-nav .item i.icon {
  color: var(--color4);
}

.nav-bar .info-nav .item .btn-1.request-a-quote {
  line-height: 40px;
  height: 40px;
  min-width: 150px;
  display: block;
}

.nav-bar.active .info-nav .item .btn-1.request-a-quote:hover {
  color: var(--color3);
}

.nav-bar.active .info-nav .item .btn-1.request-a-quote:after {
  background-color: var(--color4);
}

.nav-bar .info-nav .info-nav-contact i {
  float: left;
  font-size: 20px;
  color: var(--color6);
  line-height: 1.9;
}

.nav-bar .info-nav .info-nav-contact:hover a {
  color: var(--color2) !important;
}

.nav-bar .info-nav .info-nav-contact .contact-nav {
  padding-left: 32px;
}

.nav-bar .info-nav .info-nav-contact .contact-nav span {
  color: var(--color7);
  font-weight: 500;
  text-transform: capitalize;
  font-size: 13px;
  display: block;
  line-height: 1;
  margin-bottom: 2px;
}

.nav-bar .info-nav .info-nav-contact .contact-nav a {
  color: var(--color6);
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

.nav-bar.active .info-nav .info-nav-contact i {
  color: var(--color2);
}

.nav-bar.active .info-nav .info-nav-contact .contact-nav a {
  color: var(--color4);
}

.nav-bar .info-nav .item.cart {
  position: relative;
}

.nav-bar .info-nav .item.cart .cart-popup {
  background-color: var(--color3);
  padding: 30px;
  position: absolute;
  right: 0;
  top: calc(100% + 1px);
  z-index: 999;
  width: 300px;
  -webkit-box-shadow: 0 8px 80px rgb(40 40 40 / 8%);
  box-shadow: 0 8px 80px rgb(40 40 40 / 8%);
  -webkit-transform: translateY(60px);
  -ms-transform: translateY(60px);
  transform: translateY(60px);
  opacity: 0;
  visibility: hidden;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav-bar .info-nav .item.cart:hover .cart-popup {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.nav-bar .info-nav .item.cart .cart-popup .item {
  position: relative;
  border-bottom: 1px solid rgb(155 155 155 / 30%);
  padding-bottom: 20px;
  margin: 0 0 20px 0;
  display: block;
}

.nav-bar .info-nav .item.cart .cart-popup .item img {
  width: 50px;
  height: 50px;
  float: left;
}

.nav-bar .info-nav .item.cart .cart-popup .item .item-content {
  padding-left: 65px;
  padding-right: 25px;
}

.nav-bar .info-nav .item.cart .cart-popup .item .item-content div {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 4px;
  color: var(--color4);
  font-weight: bold;
}

.nav-bar .info-nav .item.cart .cart-popup .item .item-content span {
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  color: var(--color1);
}

.nav-bar .info-nav .item.cart .cart-popup .item .delete-item {
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: var(--color4);
  border-radius: 1px;
  color: var(--color3);
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.nav-bar .info-nav .item.cart .cart-popup .item .delete-item:hover {
  background-color: var(--color2);
}

.nav-bar .info-nav .item.cart .cart-popup .subtotal span {
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  color: var(--color1);
}

.nav-bar .info-nav .item.cart .cart-popup .subtotal span:last-of-type {
  float: right;
  color: var(--color2);
}

.nav-bar .info-nav .item.cart .cart-popup .button-cart {
  margin-top: 20px;
}

.nav-bar .info-nav .item.cart .cart-popup .button-cart button {
  outline: none;
  padding: 0 22px;
}

.nav-bar .info-nav .item.cart .cart-popup .button-cart button:first-of-type {
  margin-right: 10px;
}

.nav-bar .info-nav .item.cart .cart-popup .button-cart a {
  padding: 0 15px;
  min-width: 110px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  border-radius: 2px;
}

.nav-bar .info-nav .item.cart .cart-popup .button-cart a:first-of-type {
  margin-right: 16px;
}

/* :: Navbar Top Home 2 */
.navs-home-2 .nav-top {
  border-bottom: 1px solid rgb(249 249 249 / 0.2);
}

.navs-home-2 .nav-top .nav-top-box ul li {
  display: inline-block;
}

.navs-home-2 .nav-top .nav-top-box ul.info li {
  color: var(--color3);
  font-size: 13px;
  padding: 15px;
  line-height: 1.1;
  border-left: 1px solid rgb(249 249 249 / 0.2);
}

.navs-home-2 .nav-top .nav-top-box ul.info li:first-of-type {
  padding-left: 0;
  border-left: none;
}

.navs-home-2 .nav-top .nav-top-box ul.info li:last-of-type {
  padding-right: 0;
}

.navs-home-2 .nav-top .nav-top-box ul li span {
  color: var(--color3);
}

.navs-home-2 .nav-top .nav-top-box ul li a.icon {
  color: var(--color3);
  font-size: 14px;
  display: inline-block;
  margin-left: 15px;
}

.navs-home-2 .nav-top .nav-top-box ul li a.icon:hover {
  color: var(--color2);
}

.navs-home-2 .nav-top .nav-top-box ul li .btn-1 {
  border-radius: 0;
  display: inline-table;
  margin-left: 15px;
  line-height: 45px;
  height: 45px;
}

.navs-home-2 .nav-bar {
  border-bottom: none;
}

.navs-home-2 .nav-bar .info-nav .info-nav-contact i {
  font-size: 30px;
  line-height: 1.3;
}

.navs-home-2 .nav-bar .info-nav .info-nav-contact .contact-nav {
  padding-left: 45px;
}

/* :: Search Box */
.search-box,
.modal-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(34 34 34 / 85%);
  z-index: 1040;
  -webkit-transition: unset;
  -o-transition: unset;
  transition: unset;
  display: none;
}

.search-box form {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 85%;
}

.search-box form input {
  background-color: transparent;
  width: 100%;
  padding: 10px 0;
  font-size: 35px;
  color: var(--color3);
  border: 0;
  border-bottom: 1px solid var(--color3);
}

.search-box form input:focus {
  border-bottom: 1px solid var(--color2);
}

.search-box form button {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 65px;
  background-color: transparent;
  text-align: center;
  font-size: 25px;
  color: var(--color2);
  border: 0;
  outline: none;
}

.search-box .close-search,
.modal-box .close-search {
  position: absolute;
  right: 50px;
  top: 25px;
  font-size: 35px;
  color: var(--color3);
  cursor: pointer;
}

.search-box .close-search:hover,
.modal-box .close-search:hover {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  color: var(--color2);
}

/* :: Menu Box */
.menu-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 0.8);
  z-index: 1040;
  -webkit-transition: unset;
  -o-transition: unset;
  transition: unset;
  display: none;
}

.menu-box .inner-menu {
  width: 400px;
  padding: 100px 50px;
  position: absolute;
  right: -402px;
  height: 100%;
  background-color: var(--color3);
  overflow-y: scroll;
}

.menu-box.active .inner-menu {
  right: 0;
  -webkit-box-shadow: 0px 0px 30px rgb(0 0 0 / 0.2);
  box-shadow: 0px 0px 30px rgb(0 0 0 / 0.2);
}

.menu-box .inner-menu .website-info {
  margin-bottom: 30px;
}

.menu-box .inner-menu .website-info .logo {
  display: block;
  margin-bottom: 20px;
}

.menu-box .inner-menu .website-info .logo img {
  width: 150px;
}

.menu-box .inner-menu .website-info p {
  text-transform: capitalize;
  font-size: 14px;
}

.menu-box .inner-menu .contact-info h4,
.menu-box .inner-menu .follow-us h4 {
  position: relative;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: var(--color4);
}

.menu-box .inner-menu .contact-info .contact-box {
  margin-bottom: 30px;
}

.menu-box .inner-menu .contact-info .contact-box i {
  color: var(--color2);
  font-size: 24px;
  float: left;
  line-height: 1.4;
}

.menu-box .inner-menu .contact-info .contact-box .box {
  padding-left: 40px;
}

.menu-box .inner-menu .contact-info .contact-box .box p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 2;
  position: relative;
}

.menu-box .inner-menu .follow-us .icon-follow li {
  display: inline-block;
}

.menu-box .inner-menu .follow-us .icon-follow li a {
  background-color: var(--color6);
  display: inline-block;
  color: var(--color2);
  height: 40px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 12px;
}

.menu-box .inner-menu .follow-us .icon-follow li:hover a {
  background-color: var(--color2);
  color: var(--color3);
}

.exit-menu-box {
  font-size: 13px;
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: var(--color2);
  width: 35px;
  color: var(--color3);
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.exit-menu-box:hover {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

/* :: Header */
.header,
.header .sec-hero {
  min-height: 100vh !important;
}

.header-bg-1 {
  background-image: url(../images/header/01_header.jpg);
  background-size: cover;
}

.header-bg-2 {
  background-image: url(../images/header/02_header.jpg);
  background-size: cover;
}

.header-bg-3 {
  background-image: url(../images/header/03_header.jpg);
  background-size: cover;
}

.header .swiper-slide .banner.animate__animated {
  display: none;
}

.header .swiper-slide-active .banner.animate__animated {
  display: block !important;
}

.header .swiper-slide {
  height: 100%;
}

.header .swiper-slide.sec-hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.header .banner .headline-top {
  line-height: 23px;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
  color: var(--color3);
  position: relative;
  text-transform: capitalize;
}

.header .banner .handline {
  color: var(--color3);
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1;
  font-size: 80px;
  margin-bottom: 30px;
  position: relative;
}

.header .banner p.about-website {
  /* text-transform: capitalize; */
  text-transform: none !important;
  font-size: 16px;
  color: var(--color8);
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 30px;
  position: relative;
}

/* Swiper Slider */
.swiper-pagination {
  position: absolute;
  z-index: 5;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, 0);
  display: flex;
}

.swiper-pagination span {
  cursor: pointer;
  display: block;
  margin: 0 5px;
  width: 20px;
  height: 6px;
  border-radius: 10px;
  background-color: var(--color3);
  outline: none;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.swiper-pagination span.swiper-pagination-bullet-active {
  background-color: var(--color2) !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--color3) !important;
  opacity: 0.2;
}

.swiper .swiper-button-prev:hover,
.swiper .swiper-button-next:hover {
  opacity: 0.6 !important;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--color2);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 3px;
  stroke: var(--color2);
  fill: none;
  stroke-dashoffset: calc(160.9 * (1.095 - var(--progress)));
  stroke-dasharray: 160.9;
  transform: rotate(-90deg);
}

.header .swiper-slide .btn-2 {
  display: inline-block;
  margin-left: 30px !important;
}

/* :: Header Home 2 */
.header-home-2 .header-owl.owl-theme .owl-dots {
  bottom: calc(100px + 25px);
}

.header .petrol-info {
  padding: 50px;
  background-color: var(--color3);
  border-radius: 3px;
  width: 300px;
  position: relative;
}

.header .petrol-info img {
  width: 60px;
  margin-bottom: 30px;
}

.header .petrol-info h4 {
  font-size: 28px;
  line-height: 1;
  margin-bottom: 12px;
}

.header .petrol-info p {
  margin-bottom: 12px;
}

.header .petrol-info a {
  line-height: 1;
  color: var(--color2);
  font-weight: 600;
}

.header .petrol-info a:hover {
  color: var(--color4);
}

/* :: Features */
.features {
  padding-top: 100px;
}

.features .features-item {
  overflow: hidden;
  position: relative;
  text-align: center;
}

.features .features-item i {
  color: var(--color2);
  display: inline-block;
  font-size: 60px;
  line-height: 1;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 30px;
  position: relative;
}

.features .features-item h4 {
  font-size: 24px;
  line-height: 1.1;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
}

/* :: Features Home 2 */
.features-home-2 {
  padding: 0 0 70px 0;
}

.features-home-2 .features-owl-2 {
  margin-top: calc(-203px / 2);
}

.features-home-2 .features-item {
  background-color: var(--color3);
  padding: 30px;
  -webkit-box-shadow: 0px 0px 8px rgb(11 12 38 / 7%);
  box-shadow: 0px 0px 8px rgb(11 12 38 / 7%);
  margin: 0 0 30px 0;
}

/* :: Features Home 3 */
.features-home-3 {
  padding: 0 0 100px 0;
}

/* :: About Us */
.about-us .img-box {
  position: relative;
}

.about-us .img-box .one {
  position: relative;
  padding-top: 50px;
}

.about-us .img-box .two {
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 25px;
}

.about-us .img-box .two:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-image: url(../images/01_pattern.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
}

.about-us .img-box .about-img {
  position: relative;
}

.about-us .img-box .about-img img {
  -webkit-box-shadow: 0 1px 73px 0 rgb(11 12 38 / 0.07);
  box-shadow: 0 1px 73px 0 rgb(11 12 38 / 0.07);
  border-radius: 3px;
}

.about-us .img-box .about-img .experience-about {
  position: absolute;
  width: 175px;
  top: 0;
  margin-bottom: 0;
  background-color: var(--color3);
  padding: 30px;
  left: 50%;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  padding-top: 0;
}

.about-us .img-box .about-img .experience-about i {
  font-size: 35px;
  background-color: var(--color2);
  color: var(--color3);
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: block;
  text-align: center;
  margin-bottom: 20px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.about-us .img-box .about-img .experience-about .counter {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}

.about-us .img-box .about-img .experience-about h5 {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .5px;
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
  line-height: 1.6;
}

.about-us .text-box .sec-title {
  margin-bottom: 20px;
}

.about-us .text-box .sec-title h3 {
  margin-bottom: 20px;
}

.about-us .text-box ul {
  overflow: hidden;
}

.about-us .text-box ul li {
  width: 50%;
  float: left;
  padding-right: 10px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  text-transform: capitalize;
  color: #687076;
  padding-left: 20px;
}

.about-us .text-box ul li:hover {
  padding-left: 25px;
  color: var(--color2);
}

.about-us .text-box ul li:after {
  content: '';
  border: 2px solid #687076;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 49%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.about-us .text-box ul li:hover:after {
  border: 2px solid var(--color2);
}

.about-us .text-box .any-question {
  font-size: 16px;
  border-top: 1px solid rgb(155 155 155 / 30%);
  margin-top: 8px;
  padding-top: 20px;
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
}

.about-us .text-box .any-question a {
  color: var(--color4);
  font-weight: 500;
}

.about-us .text-box .any-question a:hover {
  color: var(--color2);
}

/* :: About US Home 2 */
.about-us-home-2 {
  padding-bottom: 100px;
}

#about-us-page .about-us-home-2 {
  padding-bottom: 0;
}

.about-us-home-2.about-page-2 {
  padding-top: 100px;
}

.about-us-home-2 .about-services {
  margin-bottom: 30px;
}

.about-us-home-2 .about-services:last-of-type {
  margin-bottom: 0;
}

.about-us-home-2 .about-services i {
  color: var(--color2);
  font-size: 50px;
  line-height: 1;
  float: left;
}

.about-us-home-2 .about-services .about-services-box {
  padding-left: 80px;
}

.about-us-home-2 .about-services .about-services-box h5 {
  color: var(--color4);
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.about-us-home-2 .about-services .about-services-box p {
  margin-bottom: 0;
}

/* :: About Us Home 3 */
.about-us-home-3 .text-box .sign-about-us img {
  width: 200px;
  margin-right: 30px;
}

.about-us-home-3 .img-box {
  margin: 0 30px;
}

.about-us-home-3 .img-box:after {
  content: '';
  background-color: var(--color2);
  position: absolute;
  width: calc(100% + 60px);
  height: 80%;
  top: 50%;
  left: 50%;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 3px;
}

.about-us-home-3 .img-box .about-img .mission {
  position: absolute;
  bottom: 40px;
  right: 30px;
  width: 300px;
  padding: 30px;
  background-color: var(--color2);
  color: var(--color3);
  border-radius: 3px;
}

.about-us-home-3 .img-box .about-img .mission:after {
  content: '';
  border: 20px solid;
  position: absolute;
  bottom: -15px;
  right: 0;
  border-color: transparent var(--color2) transparent transparent;
}

.about-us-home-3 .img-box .about-img .mission h5 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  color: var(--color3);
}

.about-us-home-3 .img-box .about-img .mission p {
  color: var(--color3);
  margin-bottom: 0;
}

.about-us-2-home-3 .img-box .logo-box {
  background-color: var(--color3);
  position: absolute;
  bottom: 30px;
  right: 30px;
  padding: 15px;
  border-radius: 3px;
}

.about-us-2-home-3 .img-box {
  margin: 15px 0;
}

.about-us-2-home-3 .img-box .logo-box img {
  width: 60px;
}

.about-us-2-home-3 .img-box:after {
  height: calc(100% + 30px);
  width: 80%;
}

/* :: Skills */
.skills .skill-box {
  margin-bottom: 30px;
}

.skills .skill-box:last-of-type {
  margin-bottom: 0;
}

.skills .skill-box .skill-top {
  overflow: hidden;
  margin-bottom: 8px;
  line-height: 1;
  display: flex;
  justify-content: space-between;
}

.skills .skill-box .skill-top .name {
  text-transform: capitalize;
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: var(--color4);
  display: inline-block;
  font-family: 'Inter', sans-serif;
}

.skills .skill-box .skill-top .number {
  /* float: right; */
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.5px;
  color: var(--color4);
  display: inline-block;
  font-family: 'Inter', sans-serif;
}

.skills .skill-box .skill-line {
  height: 14px;
  width: 100%;
  background-color: var(--color6);
  border-radius: 2px;
}

.skills .skill-box .skill-line .line {
  height: 100%;
  width: 0%;
  background-color: var(--color2);
}

/* :: Services */
.services {
  background: var(--color8) url(../images/02_pattern.png);
  position: relative;
  background-position: center;
}

.services .services-item {
  margin-bottom: 30px;
  background-color: var(--color3);
  padding: 40px;
  border-radius: 3px;
  padding-top: 0;
  position: relative;
  overflow: hidden;
}

.services .services-item span,
.work .item-work span {
  position: absolute;
  z-index: 1;
  display: block;
  width: 0px;
  height: 0px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 5px;
  opacity: 1;
  background-color: var(--color2);
}

.services .services-item:hover span,
.work .item-work:hover span {
  width: 1000px;
  height: 1000px;
}

.services .services-item i {
  font-size: 40px;
  background-color: var(--color2);
  color: var(--color3);
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: block;
  text-align: center;
  margin-bottom: 30px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  position: relative;
  z-index: 1;
}

.services .services-item:hover i {
  background-color: var(--color3);
  color: var(--color2);
}

.services .services-item h4 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
}

.services .services-item:hover h4,
.services .services-item:hover p {
  color: var(--color3);
}

.services .services-item p {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.services .services-item .more {
  margin-top: 20px;
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
  color: var(--color4);
  position: relative;
  z-index: 1;
}

.services .services-item:hover .more {
  color: var(--color3);
}

.services .services-item:hover .more:hover {
  color: var(--color4);
}

/* :: Services Home 2 */
.services-home-2 {
  background: transparent url(../images/services/01_services.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.services-home-2 .sec-title h3,
.provide-2-home-2 .sec-title h3 {
  color: var(--color3);
}

.services-home-2 .sec-title p,
.provide-2-home-2 .sec-title p {
  color: var(--color6);
}

.services-home-2 .services-item {
  background-color: transparent;
  border: 1px solid var(--color3);
}

.services-home-2 .services-item i {
  color: var(--color2);
  background-color: var(--color3);
}

.services-home-2 .services-item h4 {
  color: var(--color3);
}

.services-home-2 .services-item p {
  color: var(--color6)
}

.services-home-2 .services-item .more {
  color: var(--color3);
}

/* :: Services Home 3 */
.services-home-3 {
  background-image: url(../images/03_pattern.png)
}

.services-home-3 .services-item {
  padding: 40px;
}

.services-home-3 .services-item i {
  background-color: transparent;
  color: var(--color2);
  width: auto;
  height: auto;
  text-align: left;
  display: inline-block;
  line-height: 1.1;
  border-radius: 0;
  font-size: 50px;
}

.services-home-3 .services-item:hover i {
  background: transparent;
  color: var(--color3);
}

/* :: Provide */
.provide .col-md-6 {
  padding: 0;
}

.provide .text-box {
  background-color: var(--color2);
  padding: 100px 120px;
}

.provide .text-box .sec-title {
  margin-bottom: 50px;
}

.provide .text-box .sec-title h3 {
  margin-bottom: 25px;
  color: var(--color3);
  font-size: 52px;
}

.provide .text-box .sec-title p {
  color: var(--color6);
}

.provide .text-box .provide-features li h4 {
  color: var(--color3);
  font-weight: 500;
  /* text-transform: capitalize; */
  position: relative;
  margin-bottom: 12px;
  padding-left: 25px;
  font-size: 18px;
  line-height: 27px;
}

.provide .text-box .provide-features li:last-of-type h4 {
  margin-bottom: 0
}

.provide .text-box .provide-features li h4:after {
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  font-size: 8px;
  left: 0;
  width: 15px;
  line-height: 15px;
  height: 15px;
  background-color: var(--color3);
  color: var(--color2);
  text-align: center;
  border-radius: 2px;
  font-weight: 900;
  top: calc(50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.provide .text-box .provide-features-box {
  padding: 30px;
  background-color: var(--color3);
  border-radius: 3px;
  padding-top: 0;
}

.provide .text-box .provide-features-box i {
  font-size: 35px;
  background-color: var(--color2);
  color: var(--color3);
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: block;
  text-align: center;
  margin-bottom: 30px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.provide .text-box .provide-features-box h5 {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .5px;
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
  line-height: 1.6;
}

.provide .text-box .profile {
  margin-top: 30px;
}

.provide .text-box .profile img:first-of-type {
  width: 70px;
  border-radius: 3px;
  margin: auto;
  border: 3px solid var(--color3);
  padding: 4px;
}

.provide .text-box .profile img:last-of-type {
  width: 185px;
  margin-left: 25px;
}

.provide .img-box {
  background-image: url(../images/provide/01_provide.jpg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
}

.provide .img-box .video-box {
  background-color: var(--color3);
  position: absolute;
  bottom: 100px;
  right: 120px;
  width: 180px;
  padding: 30px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 25px 0 rgb(40, 40, 40, 0.2);
  box-shadow: 0 3px 25px 0 rgb(40, 40, 40, 0.2);
}

.provide .img-box .video-box:after {
  content: '';
  border: 20px solid;
  position: absolute;
  bottom: -15px;
  right: 0;
  border-color: transparent var(--color3) transparent transparent;
}

.provide .img-box .video-box span {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  display: inline-block;
  text-transform: capitalize;
  color: var(--color4);
  margin-bottom: 25px;
}

.pulse {
  background-color: var(--color2);
  width: 60px;
  height: 60px;
  line-height: 62px;
  border-radius: 3px;
  text-align: center;
  display: block;
  -webkit-animation: video-pulse-presentation 2s linear infinite;
  animation: video-pulse-presentation 2s linear infinite;
}

.pulse:hover {
  background-color: var(--color4);
}

@-webkit-keyframes video-pulse-presentation {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 29, 7, 0.7), 0 0 0 0 rgba(225, 29, 7, 0.7);
    box-shadow: 0 0 0 0 rgba(225, 29, 7, 0.7), 0 0 0 0 rgba(225, 29, 7, 0.7);
  }

  40% {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 0 rgba(225, 29, 7, 0.7);
    box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 0 rgba(225, 29, 7, 0.7);
  }

  80% {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 30px rgba(225, 29, 7, 0);
    box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 30px rgba(225, 29, 7, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 30px rgba(225, 29, 7, 0);
    box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 30px rgba(225, 29, 7, 0);
  }
}

@keyframes video-pulse-presentation {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 29, 7, 0.7), 0 0 0 0 rgba(225, 29, 7, 0.7);
    box-shadow: 0 0 0 0 rgba(225, 29, 7, 0.7), 0 0 0 0 rgba(225, 29, 7, 0.7);
  }

  40% {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 0 rgba(225, 29, 7, 0.7);
    box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 0 rgba(225, 29, 7, 0.7);
  }

  80% {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 30px rgba(225, 29, 7, 0);
    box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 30px rgba(225, 29, 7, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 30px rgba(225, 29, 7, 0);
    box-shadow: 0 0 0 0 rgba(225, 29, 7, 0), 0 0 0 30px rgba(225, 29, 7, 0);
  }
}

.provide .img-box .video-box .pulse i {
  color: var(--color3);
}

/* :: Provide Home 2 */
.provide-2 {
  padding-top: 100px;
  padding-bottom: 70px;
  position: relative;
  background-color: var(--color8);
}

.provide-2 .bg-section {
  background-image: url(../images/expert/01_expert.jpg);
  height: calc(100% - 0%);
}

.provide-2-item {
  margin-bottom: 30px;
  overflow: hidden;
}

.provide-2-item i {
  color: var(--color3);
  line-height: 1;
  font-size: 40px;
  float: left;
  position: relative;
}

.provide-2-item .content {
  padding-left: 60px;
}

.provide-2-item .content h4 {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: var(--color3);
  position: relative;
}

.provide-2-item .content p {
  margin-bottom: 0;
  position: relative;
  color: var(--color3);
}

/* :: Provide Home 3 */
.provide-home-3 .sec-title h2 {
  color: var(--color3);
}

.provide-home-3 .sec-title h2:after {
  background-color: var(--color3);
}

.provide-features-home-3 {
  overflow: hidden;
}

.provide-features-home-3 .features-home-3-item {
  float: left;
  width: 50%;
}

.provide-features-home-3 .features-home-3-item:first-of-type {
  padding-right: 30px;
}

.provide-features-home-3 .features-home-3-item i {
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 18px;
  display: inline-block;
  color: var(--color3);
}

.provide-features-home-3 .features-home-3-item h4 {
  margin-bottom: 12px;
  color: var(--color3);
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  font-size: 24px;
  line-height: 27px;
}

.provide-features-home-3 .features-home-3-item p {
  color: var(--color6);
  margin-bottom: 0;
}

/* :: Presentation */
.video-presentation {
  position: relative;
  background-image: url(../images/provide/01_provide.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 70px;
  width: 100%;
  height: 450px;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 73px 0 rgb(11 12 38 / 7%);
  box-shadow: 0 1px 73px 0 rgb(11 12 38 / 7%);
}

.presentation-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

/* :: Work */
.item-work {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 2px;
  margin-bottom: 30px;
}

.work .item-work span {
  /* background-color: rgb(225 29 7 / 0.8); */
  background-color: var(--color2);
  opacity: 0.8;
}

.item-work:hover .img-box img {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 2s ease-out;
}

.item-work .hover-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  z-index: 1;
}

.item-work .hover-box .open-item-work {
  font-size: 16px;
  background-color: var(--color3);
  color: var(--color2);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  position: absolute;
  top: -51px;
  left: 30px;
  transition: 0;
}

.item-work:hover .hover-box .open-item-work {
  top: 0;
}

.item-work:hover .hover-box .open-item-work:hover {
  background-color: var(--color4);
  color: var(--color3)
}

.item-work .hover-box .text-box {
  padding: 30px;
}

.item-work .hover-box .text-box .tags h5 {
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: var(--color3);
  display: inline-block;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  visibility: hidden;
  opacity: 0;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.item-work .hover-box .text-box .tags p {
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: var(--color11);
  display: block;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  visibility: hidden;
  opacity: 0;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.item-work .hover-box .text-box h4 {
  color: var(--color3);
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: .3px;
  font-size: 26px;
  margin-top: 10px;
  display: inline-block;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  visibility: hidden;
  opacity: 0;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.item-work:hover .hover-box .text-box .tags h5,
.item-work:hover .hover-box .text-box .tags p,
.item-work:hover .hover-box .text-box h4 {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  visibility: visible;
  opacity: 1;
}

.work-list-filter .list-name-work {
  margin-bottom: 45px;
  display: inline-block;
}

.work-list-filter .list-name-work li {
  display: inline-block;
  font-size: 13px;
  color: var(--color4);
  text-transform: capitalize;
  padding: 0 20px;
  line-height: 40px;
  border-radius: 3px;
  letter-spacing: 0.25px;
  position: relative;
  text-align: center;
  font-weight: 600;
  border: 1px solid var(--color9);
  margin: 0 8px 5px 0;
  cursor: pointer;
}

.work-list-filter .list-name-work li.active {
  background-color: var(--color2);
  color: var(--color3);
  border: 1px solid var(--color2);
}

.work-list-filter .list-name-work li:last-of-type {
  margin: 0;
}

.mix {
  -webkit-transition: unset;
  -o-transition: unset;
  transition: unset;
}

/* :: Work Home 2 */
.work-home-2 .owl-theme .owl-dots,
.work-home-2 .owl-theme .owl-nav {
  display: none;
}

/* :: Contact */
.contact {
  background-image: url(../images/contact/01_contact.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.contact .sec-title {
  margin-bottom: 0;
}

.contact .sec-title h3 {
  color: var(--color3);
  margin-bottom: 25px;
  font-size: 85px;
  line-height: 1;
}

.contact .sec-title p {
  color: var(--color6);
  font-size: 17px;
  margin-bottom: 30px;
}

/* :: Team */
.team-box {
  margin-bottom: 30px;
}

.team-box .img-box {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}

.team-box:hover .img-box img {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 2s ease-out;
}

.team-box .img-box ul {
  position: absolute;
  top: 15px;
  left: -40px;
  opacity: 0;
  visibility: hidden;
}

.team-box:hover .img-box ul {
  left: 15px;
  opacity: 1;
  visibility: visible;
}

.team-box .img-box ul li i {
  display: block;
  margin-bottom: 8px;
  -webkit-box-shadow: 0px 0px 10px 0px rgb(200 157 102 / 0.5);
  box-shadow: 0px 0px 10px 0px rgb(200 157 102 / 0.5);
  background-color: var(--color2);
  font-size: 14px;
  color: var(--color3);
  text-transform: capitalize;
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: relative;
  text-align: center;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;
}

.team-box .img-box ul li:hover i {
  color: var(--color2);
  background-color: var(--color3);
  -webkit-box-shadow: 0px 0px 10px 0px rgb(255 255 255 / 0.5);
  box-shadow: 0px 0px 10px 0px rgb(255 255 255 / 0.5);
}

.team-box .text-box {
  margin-top: 25px;
  padding: 0 40px;
}

.team-box .text-box h5 {
  line-height: 1;
  text-transform: capitalize;
  font-size: 26px;
  font-weight: 500;
  color: var(--color4);
}

.team-box .text-box span {
  display: inline-block;
  line-height: 1;
  font-weight: 500;
  color: var(--color2);
  margin: 10px 0 0 0;
  text-transform: uppercase;
  font-size: 14px;
}

/* :: Team Home 2 */
.team-home-2 .team-box .img-box ul {
  background-color: var(--color2);
  top: -50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.team-home-2 .team-box:hover .img-box ul {
  top: 15px;
}

.team-home-2 .team-box .img-box ul li {
  display: inline-block;
}

.team-home-2 .team-box .img-box ul li i {
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  border-radius: 0;
}

/* :: expert */
.expert {
  padding-top: 100px;
  position: relative;
  background-color: var(--color8);
}

.expert .bg-section {
  background-image: url(../images/expert/01_expert.jpg);
  height: calc(100% - 300px)
}

.expert .sec-title {
  margin-bottom: 0;
}

.expert .sec-title h2 {
  color: var(--color3)
}

.expert .sec-title h3 {
  margin-bottom: 30px;
  color: var(--color3);
  font-size: 65px;
}

.expert .sec-title p {
  color: var(--color6);
  margin-bottom: 30px;
}

.expert .statistic-slider {
  background-color: var(--color3);
  padding: 0;
  border-radius: 3px;
}

.expert .statistic-slider .swiper-slide {
  padding: 50px;
  padding-top: 0;
}

.expert .statistic-slider .statistic-item {
  overflow: hidden;
}

.expert .statistic-slider .statistic-item i {
  font-size: 40px;
  background-color: var(--color2);
  color: var(--color3);
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: block;
  text-align: center;
  margin-bottom: 50px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  position: relative;
  z-index: 1;
}

.expert .statistic-slider .statistic-item .counter {
  font-size: 60px;
  font-weight: 600;
  line-height: 0.8;
  margin-bottom: 20px;
  display: block;
  font-family: 'Inter', sans-serif;
}

.expert .statistic-slider .statistic-item .name-content {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: .5px;
}

.expert .statistic-slider .swiper-pagination {
  position: absolute;
  margin: 0;
  line-height: 1;
  top: 30px;
  right: 50px;
  left: auto;
}

.expert .statistic-slider .swiper-pagination span {
  outline: none;
}

.expert .statistic-slider .swiper-pagination span {
  background-color: rgb(225 29 7 / 0.3);
  width: 18px;
  height: 5px;
}

.expert .statistic-slider .swiper-pagination span.active,
.expert .statistic-slider .swiper-pagination span:hover {
  background-color: var(--color2);
}

/* :: Quote */
.quote {
  position: relative;
  margin-top: 100px;
}

.modal-content {
  position: absolute;
  z-index: 77;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
  max-height: 80%;
  overflow-y: scroll;
}

.quote-box {
  background-color: var(--color3);
  -webkit-box-shadow: 0 1px 73px 0 rgb(11 12 38 / 0.07);
  box-shadow: 0 1px 73px 0 rgb(11 12 38 / 0.07);
  padding: 30px;
  border-radius: 3px;
}

.quote-info {
  height: 100%;
  color: var(--color3);
  background: url(../images/quote/01_quote.jpg);
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  padding: 30px;
}

.quote-info:after {
  content: '';
  /* background-color: rgb(225 29 7 / 0.8); */
  background-color: var(--color2);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.quote-info .info-top,
.quote-info .info-down {
  position: relative;
  z-index: 1;
  width: 100%
}

.quote-info .info-top .item {
  margin-bottom: 30px;
}

.quote-info .info-top .item h5 {
  font-weight: 500;
  font-size: 25px;
  line-height: 1.2;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.quote-info .info-top .item p {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1.5;
  position: relative;
  color: var(--color6)
}

.quote-info .info-top .item a {
  display: block;
  font-weight: 700;
}

.quote-info .info-top .item a:hover {
  color: var(--color4);
}

.quote-info .info-down svg {
  font-size: 20px;
  display: inline-block;
  text-align: center;
  line-height: 37px;
}

.quote-info .info-down a {
  font-weight: 700;
  font-size: 21px;
  line-height: 1.2;
  display: inline-block;
  text-transform: capitalize;
  margin-left: 12px;
}

.quote-info .info-down a:hover {
  color: var(--color4)
}

.text-box .sec-title h3 {
  color: var(--color4);
  margin-bottom: 15px;
  font-size: 35px;
}

.text-box .sec-title p {
  color: var(--color1);
}

.quote-item input,
.quote-item textarea {
  border: 2px solid var(--color9);
  padding: 12px;
  width: 100%;
  color: var(--color10);
  font-size: 13px;
  margin-bottom: 30px;
  border-radius: 3px;
}

.quote-item input:focus,
.quote-item textarea:focus {
  border-color: var(--color2);
}

.quote-item textarea {
  display: block;
  min-height: 150px;
}

.nice-select {
  border: 2px solid var(--color9);
  padding: 12px;
  width: 100%;
  color: var(--color10);
  font-size: 13px;
  margin-bottom: 30px;
  border-radius: 2px;
  height: auto;
  line-height: unset;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: var(--color2);
}

.nice-select .list {
  background-color: var(--color3);
  min-width: 250px;
  padding: 25px 0;
  border-color: var(--color3);
  border-radius: 2px;
}

.nice-select .option {
  color: var(--color1);
  padding: 0;
  margin: 0 20px;
  font-size: 13px;
  line-height: 40px;
  border-bottom: 1px solid rgb(155 155 155 / 30%);
  position: relative;
  font-weight: normal;
  text-transform: capitalize;
  display: block;
  font-weight: 400;
}

.nice-select .option:last-of-type {
  border: 0;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: transparent;
  color: var(--color2);
  font-weight: 400;
}

/* :: FAQs & Quote */
.faq-quote {
  background-image: url(../images/contact/01_contact.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.faq-quote .faq .sec-title h3 {
  color: var(--color3);
  margin-bottom: 25px;
}

.faq-quote .faq .sec-title p {
  color: var(--color6);
}

.faq .faq-box {
  padding: 18px;
  margin-bottom: 25px;
  border-radius: 3px;
  border: 1px solid var(--color3);
  position: relative;
}

.faqs-page .img-box .about-img {
  position: relative;
}

.faqs-page .img-box {
  margin: 0 30px;
  position: relative;
}

.faqs-page .img-box .about-img img {
  box-shadow: 0 1px 73px 0 rgb(11 12 38 / 0.07);
  border-radius: 3px;
}

.faqs-page .img-box:after {
  content: '';
  background-color: var(--color2);
  position: absolute;
  width: calc(100% + 60px);
  height: 80%;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  border-radius: 3px;
}

.faqs-page .img-box .about-img .mission {
  position: absolute;
  bottom: 40px;
  right: 30px;
  width: 300px;
  padding: 30px;
  background-color: var(--color2);
  color: var(--color3);
  border-radius: 3px;
}

.faqs-page .img-box .about-img .mission h5 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  color: var(--color3);
}

.faqs-page .img-box .about-img .mission p {
  color: var(--color3);
  margin-bottom: 0;
}

.faq .faq-box:last-of-type {
  margin-bottom: 0;
}

.faqs-page .faq-box:last-of-type {
  margin-bottom: 30px;
}

.faq .faq-box .question-header {
  line-height: 1;
}

.faq .faq-box .question-header .click {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 20px;
  color: var(--color3);
  font-weight: 500;
  line-height: 1.4;
  text-transform: capitalize;
  position: relative;
  width: 100%;
  text-align: left;
  padding-right: 20px;
  outline: inherit;
}

.faq .faq-box .question-header .click svg {
  color: var(--color2);
  font-weight: 600;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 20px;
  text-align: center;
}

.faq .faq-box .question-header .click:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.faq .faq-box .question-header .click[aria-expanded="true"] svg {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}

.faq .faq-box .about-text {
  margin-top: 18px;
  margin-bottom: 0;
  color: var(--color6);
  letter-spacing: 0.3px;
  background-color: transparent;
  border: 0;
  padding: 0;
  border-top: 1px solid var(--color8);
  padding-top: 18px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0;
}

/* :: Quote */
.faq-quote .quote {
  padding: 30px;
  background-color: var(--color3);
  border-radius: 3px;
  margin-top: 0;
}

.faq-quote .quote .sec-title {
  margin-bottom: 30px;
}

.faq-quote .quote .sec-title h3 {
  color: var(--color4);
  font-size: 35px;
}

.faq-quote .quote .quote-item {
  background-color: var(--color8);
  margin-bottom: 30px;
  padding: 15px;
  position: relative;
  border-radius: 3px;
}

.faq-quote .quote .quote-item:last-of-type {
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
}

.faq-quote .quote .quote-item label {
  display: block;
  line-height: 1.1;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color4);
}

.faq-quote .quote .quote-item input,
.faq-quote .quote .quote-item textarea {
  width: 100%;
  color: var(--color1);
  background-color: transparent;
  font-size: 13px;
  padding: 10px 0 0 0;
  text-transform: capitalize;
  letter-spacing: 0.25px;
  position: relative;
  border: 0px solid transparent;
  margin-bottom: 0;
}

.faq-quote .quote .quote-item textarea {
  height: 115px;
  display: block;
}

.faq-quote .quote .quote-item i {
  position: absolute;
  color: var(--color2);
  top: 15px;
  right: 15px;
}

/* :: Sponsors */
.sponsors {
  position: relative;
  padding: 50px 0;
  background-color: var(--color8);
}

.sponsors.page {
  padding: 100px 0;
}

.sponsors img {
  opacity: 0.5;
  display: block;
  margin: auto;
  width: 50%;
  height: auto;
}

.sponsors img:hover {
  opacity: 1;
}

/* :: Testimonial */
.testimonial,
.testimonial-home-2 {
  background-color: var(--color8);
}

.testimonial-slider .item-box .text-box {
  position: relative;
  font-size: 30px;
  line-height: 45px;
  font-weight: 500;
  color: var(--color4);
  margin-bottom: 30px;
  margin-top: calc(69px + 30px);
  text-align: center;
}

.testimonial-slider .item-box .item-name i {
  font-size: 50px;
  line-height: 1;
  color: var(--color2);
  display: block;
}

.testimonial-slider .item-box .item-name h5,
.testimonial-home-2 .testimonial-slider .testimonial-item .item-name h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin-top: 15px;
  margin-bottom: 8px;
  text-transform: capitalize;
  color: var(--color4);
  text-align: center;
}

.testimonial-slider .item-box .item-name span,
.testimonial-home-2 .testimonial-slider .testimonial-item .item-name span {
  display: block;
  line-height: 15px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  color: var(--color1);
}

.testimonial .testimonial-slider .swiper-pagination {
  line-height: 1;
  position: absolute;
  width: 100%;
  top: 0;
}

.testimonial .testimonial-slider .swiper-pagination span {
  margin: 0 8px;
  border-radius: 3px;
  padding: 5px !important;
  outline: none;
}

.testimonial .testimonial-slider .swiper-pagination .swiper-pagination-bullet-active {
  border: 2px solid var(--color2);
}

.testimonial .testimonial-slider .swiper-pagination span {
  background-color: transparent;
  background-size: cover;
  width: 55px;
  height: 55px;
  margin: auto;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.testimonial .testimonial-slider .swiper-pagination span:nth-of-type(1) {
  background-image: url(../images/testimonial/01_testimonial.jpg);
  margin-left: -100px;
}

.testimonial .testimonial-slider .swiper-pagination span:nth-of-type(2) {
  background-image: url(../images/testimonial/02_testimonial.jpg);
  margin-left: 0;
}

.testimonial .testimonial-slider .swiper-pagination span:nth-of-type(3) {
  background-image: url(../images/testimonial/03_testimonial.jpg);
  margin-left: 100px;
}

/* .testimonial .testimonial-slider.owl-theme .owl-nav {
  display: none;
} */

/* :: Testimonial Home 2 */
.testimonial-home-2 .icon-quote {
  margin: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}

.testimonial-home-2 .icon-quote i {
  font-size: 42px;
  line-height: 1;
  color: var(--color2);
}

.testimonial-home-2 .testimonial-slider .testimonial-item .text-box {
  position: relative;
  font-size: 30px;
  line-height: 45px;
  font-weight: 500;
  color: var(--color4);
  font-family: 'Inter', sans-serif;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.testimonial-home-2 .testimonial-slider .testimonial-item .text-box:after,
.testimonial-home-2 .testimonial-slider .testimonial-item .text-box:before {
  content: ' " ';
}

.testimonial-home-2 .testimonial-slider .testimonial-item .item-name img {
  width: 70px;
  border-radius: 50%;
  margin: auto;
  border: 3px solid var(--color2);
  padding: 4px;
}

.testimonial-home-2 .testimonial-slider.owl-theme .owl-nav {
  display: none;
}

.testimonial-home-2 .testimonial-slider.owl-theme .owl-dots {
  margin: 0;
  line-height: 1;
  margin-top: 30px;
}

.testimonial-home-2 .testimonial-slider.owl-theme .owl-dots .owl-dot {
  outline: none;
}

.testimonial-home-2 .testimonial-slider.owl-theme .owl-dots .owl-dot span {
  margin: 0 5px;
  width: 20px;
  height: 6px;
  background-color: var(--color4);
  outline: none;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.testimonial-home-2 .testimonial-slider.owl-theme .owl-dots .owl-dot.active span,
.testimonial-home-2 .testimonial-slider.owl-theme .owl-dots .owl-dot:hover span {
  background-color: var(--color2);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* :: Testimonial Home 3 */
.testimonial-home-3 {
  background-image: url(../images/expert/01_expert.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.testimonial-home-3 .sec-title h3 {
  color: var(--color3);
}

.testimonial-home-3 .sec-title p {
  color: var(--color6);
}

.testimonial-home-3 .testimonial-item .text-box {
  background-color: var(--color3);
  padding: 0 30px 30px 30px;
  border-radius: 3px;
  position: relative;
  color: var(--color1);
  font-size: 15px;
  margin-bottom: 30px
}

.testimonial-home-3 .testimonial-item .text-box:after {
  content: '';
  position: absolute;
  border: 20px solid;
  border-color: transparent transparent transparent var(--color3);
  left: 0;
  bottom: -10px;
  z-index: 0;
}

.testimonial-home-3 .testimonial-item .text-box i {
  font-size: 30px;
  background-color: var(--color2);
  color: var(--color3);
  width: 55px;
  height: 55px;
  line-height: 55px;
  display: block;
  text-align: center;
  margin-bottom: 30px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  position: relative;
  z-index: 1;
}

.testimonial-home-3 .testimonial-item .item-name img {
  width: 60px;
  height: 60px;
  border-radius: 3px;
  border: 2px solid var(--color3);
  padding: 3px;
}

.testimonial-home-3 .testimonial-item .item-name h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin-top: 15px;
  margin-bottom: 8px;
  text-transform: capitalize;
  color: var(--color3);
}

.testimonial-home-3 .testimonial-item .item-name span {
  display: block;
  line-height: 15px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--color6);
}

/* :: News */
.news-item {
  margin-bottom: 30px;
}

.news-item .img-box {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  height: 270px;
}

.news-item .img-box .open-item-news {
  font-size: 16px;
  background-color: var(--color3);
  color: var(--color2);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  position: absolute;
  top: -51px;
  left: 30px;
  z-index: 1;
}

.news-item .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-item:hover .img-box .open-item-news {
  top: 0;
}

.news-item:hover .img-box .open-item-news:hover {
  background-color: var(--color2);
}

.news-item:hover .img-box .open-item-news:hover i {
  color: var(--color3);
}

.news-item:hover .img-box .open-post img {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: all 1.5s ease-in-out;
  -o-transition: all 1.5s ease-in-out;
  transition: all 1.5s ease-in-out
}

.news-item .img-box ul {
  position: absolute;
  bottom: 0;
  left: 30px;
  background-color: var(--color2);
  padding: 2px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.news-item .img-box ul li {
  display: inline-block;
  font-size: 13px;
  text-transform: capitalize;
  color: var(--color3);
}

.news-item .text-box {
  display: block;
}

.news-item .text-box .title-news {
  display: block;
}

.news-item .text-box h5 {
  position: relative;
  font-size: 21px;
  line-height: 30px;
  margin: 0 0 15px 0;
  font-weight: 600;
  color: var(--color4);
  text-transform: capitalize;
}

.news-item .text-box .title-news:hover,
.news-item .text-box:hover h5,
.news-item .text-box:hover .link {
  color: var(--color2);
}

.news-item .text-box .news-date {
  margin-bottom: 15px;
  display: block;
  color: var(--color4);
}

.news-item .text-box .link {
  font-size: 14px;
  color: var(--color4);
  display: inline-block;
  text-transform: capitalize;
  font-weight: 600;
}

.news-item .text-box .link:hover {
  color: var(--color2);
}

ul.news-pagination {
  display: flex;
  justify-content: center;
}

ul.news-pagination li {
  margin: auto 5px;
}

ul.news-pagination li a {
  padding: 7px 12px;
  font-size: 16px;
  color: var(--color2) !important;
}

ul.news-pagination li:hover a {
  color: var(--color4) !important;
}

ul.news-pagination li.selected a {
  background-color: var(--color2);
  color: var(--color3) !important;
  border-radius: 5px;
}

/* :: Map */
.map-box iframe {
  border: 0;
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
  height: 180px;
  display: block;
  margin-bottom: calc(-180px /2);
}

/* :: Footer */
.footer {
  background-color: var(--color4);
  padding-top: 100px;
}

.footer-home-2 {
  padding-top: calc(100px + 90px);
}

.footer .logo img.two {
  display: none;
}

.footer .logo,
.footer .links,
.footer .newsletter {
  margin-bottom: 30px;
}

.footer ul.links {
  display: inline-flex;
  flex-direction: column;
}

.footer ul.links li {
  display: flex;
}

.footer .logo img {
  width: 150px;
}

.footer .logo p {
  margin: 30px 0 30px 0;
  color: var(--color8);
}

.footer-title h4 {
  color: var(--color7);
  font-size: 22px;
  text-transform: capitalize;
  line-height: 1;
  margin-bottom: 25px;
}

.footer .links li a {
  display: inline-block;
  position: relative;
  color: var(--color8);
  font-size: 14px;
  line-height: 32px;
}

.footer .links li a.active {
  color: var(--color2);
}

.footer .links li:hover a {
  color: var(--color2);
}

.footer .newsletter {
  background-color: #323232;
  padding: 30px;
  border-radius: 3px;
}

.footer .newsletter h5 {
  color: var(--color2);
  font-size: 22px;
  text-transform: capitalize;
  line-height: 1;
  margin-bottom: 20px;
}

.footer .newsletter p {
  color: #F4F4F4;
  margin-bottom: 26px;
  font-size: 15px;
  font-weight: 500;
}

.footer .newsletter form {
  position: relative
}

.footer .newsletter form input {
  width: 100%;
  color: var(--color1);
  background-color: transparent;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0 15px;
  line-height: 44px;
  border-radius: 3px;
  letter-spacing: 0.25px;
  position: relative;
  border: 1px solid rgb(204 204 204 / 0.1);
  text-transform: lowercase;

}

.footer .newsletter form input:focus {
  border-color: var(--color2);
}

.footer .newsletter form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  background-color: var(--color2);
  color: var(--color3);
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  color: var(--color3);
  outline: none !important;
  border: none;
}

.footer .newsletter form button:hover {
  background-color: var(--color4);
}

.footer .map-box iframe {
  margin-bottom: 30px;
}

.footer ul.icon {
  margin-bottom: 30px;
}

.footer ul.icon li {
  display: inline-block;
}

.footer ul.icon li a {
  width: 35px;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #323232;
  color: var(--color3);
  font-size: 13px;
  margin-right: 5px;
  border-radius: 2px;
}

.footer ul.icon li:hover a {
  background-color: var(--color3);
  color: var(--color2);
}

.copyright {
  margin-top: 70px;
  padding: 40px 0;
  background-color: #323232;
  overflow: hidden
}

.copyright p {
  margin: 0;
  color: var(--color7);
  float: left;
}

.copyright p a {
  color: var(--color2);
}

.copyright p a:hover {
  color: var(--color3);
}

.copyright ul {
  float: right;
}

.copyright ul li {
  display: inline-block;
  margin-left: 25px;
}

.copyright ul li p {
  margin-right: 5px;
}

.copyright ul li a {
  color: var(--color2) !important
}

.copyright ul li:hover a {
  color: var(--color3) !important;
}

/* :: Scroll Up */
.scroll-up {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 20px;
  -webkit-box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.05);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  border-radius: 3px;
  overflow: hidden;
}

.scroll-up.active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.scroll-up .move-section {
  background-color: var(--color2);
  font-size: 16px;
  color: var(--color3);
  line-height: 42px;
  width: 44px;
  height: 44px;
  display: inline-block;
  text-align: center;
  border: 1px solid var(--color2);
  border-radius: 3px;
  outline: none;
}

.scroll-up:hover .move-section {
  background-color: var(--color4);
  border: 1px solid var(--color4);
}

/* :: Color Change */
.color-change {
  position: fixed;
  z-index: 100;
  bottom: 80px;
  right: 20px;
  -webkit-box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.05);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  border-radius: 3px;
}

.color-change.active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.color-change .text {
  display: flex;
  justify-content: space-between;
  background: transparent;
  padding: 0 4px 0 2px;
}

.color-change .text span {
  margin-top: -7px;
  background: transparent;
  font-size: 7px;
  line-height: 0;
  color: var(--color2);
}

.color-change .form-control-color {
  padding: 0 !important;
  width: 44px;
  height: 44px;
  background: transparent !important;
  border: none !important;
  border-radius: 3px;
  outline: none !important;
  overflow: hidden;
}

.color-change i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: var(--color3);
}

.color-change:hover i {
  display: none;
}

/* :: Breadcrumb Header */
.breadcrumb-header {
  padding-bottom: 120px;
  padding-top: calc(86px + 120px);
  color: var(--color3);
  position: relative;
  background-image: url(../images/header/02_header.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.breadcrumb-header-2 {
  /* padding-top: calc(131px + 120px); */
  background-image: url(../images/header/07_header.jpg) !important;
}

#news-page .breadcrumb-header-2 {
  /* padding-top: calc(131px + 120px); */
  background-image: url(../images/header/05_header.jpg) !important;
}

#faq-page .breadcrumb-header-2 {
  /* padding-top: calc(131px + 120px); */
  background-image: url(../images/header/04_header.jpg) !important;
}

#contacts-page .breadcrumb-header-2 {
  /* padding-top: calc(131px + 120px); */
  background-image: url(../images/header/06_header.jpg) !important;
}

.breadcrumb-header .container {
  position: relative;
}

.breadcrumb-header .banner h1 {
  font-size: 80px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--color3);
  text-transform: capitalize;
  margin-bottom: 25px;
  position: relative;
}

.breadcrumb-header .banner p {
  color: var(--color3) !important;
  font-size: 20px;
  line-height: 1.5;
}

.breadcrumb-header .banner ul {
  margin-top: 50px;
}

.breadcrumb-header .banner ul li {
  display: inline-block;
  position: relative;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.30px;
  color: var(--color2);
  text-transform: capitalize;
}

.breadcrumb-header .banner ul li:hover a {
  color: var(--color2);
}

#about-us-page .features {
  padding-top: 0;
  padding-bottom: 100px;
}

/* :: Brochures */
.brochures {
  padding: 40px;
  background-color: var(--color6);
  margin-bottom: 30px;
  border-radius: 3px;
}

.brochures h5 {
  margin-bottom: 10px;
  text-transform: capitalize;
  color: var(--color4);
  font-size: 24px;
  font-weight: 500;
}

.brochures a {
  width: 100%;
}

/* :: Dedicated Customer */
.dedicated-customer {
  padding: 40px;
  background-color: var(--color2);
  border-radius: 3px;
  color: var(--color3);
  margin-bottom: 30px;
  border-radius: 3px;
}

.dedicated-customer h5 {
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: var(--color3);
  font-size: 24px;
}

.dedicated-customer p {
  margin-bottom: 100px;
  color: var(--color6)
}

.dedicated-customer .btn-1 {
  display: block;
}

.dedicated-customer .btn-1:hover {
  color: var(--color3);
}

.dedicated-customer .btn-1:after {
  background-color: var(--color4);
}

/* :: Introduction Member */
.introduction-member h3 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 25px;
  margin-top: 40px;
}

.introduction-member h3:first-of-type {
  margin-top: 0;
}

.introduction-member .skills {
  margin-bottom: 30px;
}

/* :: Core About */
.core-about {
  overflow: hidden;
}

.core-about li {
  margin-bottom: 12px;
  float: left;
  width: 50%;
}

.core-about li i {
  font-size: 12px;
  color: var(--color2);
  margin-right: 8px;
}

.core-about li h4 {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: var(--color4);
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
}

/* :: Services Details */
.single-services h3 {
  font-size: 45px;
  line-height: 1.1;
  color: var(--color4);
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 25px;
}

.single-services h4 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 25px;
  margin-top: 40px;
}

.single-services .single-services-list {
  padding: 30px;
  background-color: var(--color6);
  border-radius: 3px;
  margin-bottom: 30px;
}

.single-services .single-services-list h4 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 0 25px 0;
}

.single-services .single-services-list ul {
  background-color: var(--color3);
}

.single-services .single-services-list ul li a {
  display: block;
  color: var(--color4);
  font-weight: 500;
  padding: 20px 15px;
  border-bottom: 2px solid var(--color6);
  position: relative;
  cursor: pointer;
}

.single-services .single-services-list ul li.active a,
.single-services .single-services-list ul li:hover a {
  color: var(--color2)
}

.single-services .single-services-list ul li a i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.benfits {
  overflow: hidden;
}

.benfits li {
  width: 50%;
  float: left;
  padding-right: 10px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  text-transform: capitalize;
  color: #687076;
}

/* :: Single News Page */
.single-news .news-item .text-box {
  padding-left: 0;
}

.share-post {
  overflow: hidden;
  border-top: 1px solid var(--color11);
  border-bottom: 1px solid var(--color11);
  padding-top: 25px;
  margin-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.share-post span {
  font-size: 15px;
  font-weight: 600;
  color: var(--color4);
  margin: 4px 0;
  display: inline-block;
}

.share-post ul {
  float: right;
}

.share-post ul li {
  display: inline-block;
  margin: 0 4px 0px 0;
}

.share-post ul li a {
  display: inline-block;
  text-align: center;
  color: var(--color4);
  background-color: var(--color8);
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid var(--color8);
  border-radius: 2px;
}

.share-post ul li:hover a {
  background-color: var(--color2);
  border-color: var(--color2);
  color: var(--color3);
}

.single-news .item-comments {
  margin-bottom: 30px;
}

.single-news .item-comments .title h4,
.single-news .add-comments .title h4 {
  line-height: 1;
  text-transform: capitalize;
  color: var(--color4);
  position: relative;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 25px;
}

.single-news .item-comments .inner-comments .comments-box {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color11);
}

.single-news .item-comments .inner-comments .comments-box:last-of-type {
  padding: 0;
  margin: 0;
  border: none;
}

.single-news .item-comments .inner-comments .comments-box.reply,
.single-news .item-comments .inner-comments .comments-box .text-box {
  margin-left: 90px;
}

.single-news .item-comments .inner-comments .comments-box .img-box {
  height: 70px;
  width: 70px;
  display: block;
  margin-right: 20px;
  float: left;
  position: relative;
}

.single-news .item-comments .inner-comments .comments-box .img-box img {
  border-radius: 50%;
}

.single-news .item-comments .inner-comments .comments-box .text-box h5 {
  display: block;
  color: var(--color4);
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.single-news .item-comments .inner-comments .comments-box .text-box .time {
  line-height: 20px;
  margin-bottom: 5px;
  font-size: 12px;
  margin: 0 0 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--color2);
}

.single-news .item-comments .inner-comments .comments-box .text-box p {
  margin-bottom: 15px;
}

.single-news .item-comments .inner-comments .comments-box .text-box a {
  display: inline-block;
  padding: 4px 10px;
  border: 1px solid var(--color8);
  background-color: var(--color8);
  font-size: 13px;
  font-weight: 600;
  color: #7A8A9E;
  border-radius: 3px;
}

.single-news .item-comments .inner-comments .comments-box .text-box a:hover {
  color: var(--color3);
  border: 1px solid var(--color2);
  background-color: var(--color2);
}

.single-news .add-comments .inner-add-comments-box {
  margin-bottom: 30px;
}

.single-news .add-comments .inner-add-comments-box input,
.single-news .add-comments .inner-add-comments-box textarea {
  position: relative;
  border: 1px solid var(--color9);
  padding: 10px;
  width: 100%;
  color: var(--color10);
  font-size: 13px;
  border-radius: 3px;
}

.single-news .add-comments .inner-add-comments-box input:focus,
.single-news .add-comments .inner-add-comments-box textarea:focus {
  border-color: var(--color2);
}

.single-news .add-comments .inner-add-comments-box textarea {
  height: 100px;
  display: block;
}

/* :: Widget */
.widget {
  margin-bottom: 30px;
}

.widget .widget-title {
  padding-bottom: 25px;
}

.widget .widget-title h3 {
  line-height: 1.2;
  text-transform: capitalize;
  color: var(--color4);
  position: relative;
  font-size: 22px;
  font-weight: 500;
}

.widget .widget-body .search {
  position: relative;
}

.widget .widget-body .search input {
  position: relative;
  border: 1px solid var(--color9);
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  color: var(--color10);
  font-size: 13px;
}

.widget .widget-body .search input:focus {
  border-color: var(--color2);
}

.widget .widget-body .search button.click {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  outline: none;
  font-size: 13px;
  background-color: transparent;
  color: var(--color2);
  border: none;
}

.widget .widget-body .instagram ul {
  overflow: hidden;
}

.widget .widget-body .instagram ul li {
  /* float: left; */
  display: inline-block;
  margin: 4px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}

.widget .widget-body .instagram ul li img {
  width: 60px;
  height: 60px;
}

.widget .widget-body .instagram ul li a {
  background-color: var(--color2);
  color: var(--color3);
  font-size: 14px;
  line-height: 58px;
  cursor: pointer;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.widget .widget-body .instagram ul li:hover a {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.widget .widget-body .follow .icon li {
  display: inline-block;
  margin: 0 4px 4px 0;
}

.widget .widget-body .follow .icon li a {
  display: inline-block;
  text-align: center;
  color: var(--color3);
  background-color: var(--color2);
  font-size: 13px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid var(--color2);
  border-radius: 2px;
  cursor: pointer;
}

.widget .widget-body .follow .icon li:hover a {
  background-color: var(--color4);
  border-color: var(--color4);
}

.widget .categories ul li {
  border-bottom: 1px solid var(--color11);
}

.widget .categories ul li:last-of-type {
  border-bottom: none;
}

.widget .categories ul li a {
  font-size: 14px;
  padding: 15px 0;
  display: block;
  line-height: 15px;
  text-transform: capitalize;
  color: var(--color10);
}

.widget .categories ul li:first-of-type a {
  padding-top: 0;
}

.widget .categories ul li:last-of-type a {
  padding-bottom: 0;
}

.widget .categories ul li a i {
  margin-right: 5px;
}

.widget .categories ul li a span {
  float: right;
}

.widget .categories ul li:hover a {
  color: var(--color2);
  padding-left: 10px;
}

.widget .widget-body .tags ul li {
  display: inline-block;
}

.widget .widget-body .tags ul li a {
  display: inline-block;
  padding: 4px 10px;
  margin: 0 5px 5px 0;
  border: 1px solid var(--color8);
  background-color: var(--color8);
  font-size: 13px;
  font-weight: 600;
  color: var(--color10);
  border-radius: 3px;
  text-transform: capitalize;
}

.widget .widget-body .tags ul li:hover a {
  background-color: var(--color2);
  color: var(--color3);
  border: 1px solid var(--color2);
}

.news-item {
  margin-bottom: 22px;
  overflow: hidden;
}

.news-item:last-of-type {
  margin-bottom: 0px;
}

.news-item img {
  float: left;
  height: 60px;
  width: 60px;
  border-radius: 3px;
}

.news-item .item-content {
  padding-left: 70px;
}

.news-item .item-content span a {
  color: var(--color2);
  line-height: 0.8;
  display: block;
  padding-bottom: 8px;
}

.news-item .item-content a.title-news {
  display: block;
}

.news-item .item-content a.title-news h5 {
  color: #B4B4B4;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.1;
}

.news-item .item-content a.title-news:hover,
.news-item .item-content a.title-news:hover h5 {
  color: var(--color2);
}

/* :: Pagination */
.pagination-area {
  margin-bottom: 30px;
}

.pagination-area .pagination li {
  display: inline-block;
  text-align: center;
  color: var(--color4);
  font-weight: 600;
  font-size: 14px;
  width: 44px;
  height: 44px;
  line-height: 42px;
  margin-right: 2px;
  border: 1px solid var(--color11);
  cursor: pointer;
}

.pagination-area .pagination li:last-of-type {
  margin-right: 0;
}

.pagination-area .pagination li.active {
  background-color: var(--color2);
  color: var(--color3);
  border-color: var(--color2);
}

/* :: FAQs 2 */
.faq-2 .faq-box {
  border: 2px solid var(--color6);
  margin-bottom: 30px;
}

.faq-2 .faq-box .question-header .click {
  color: var(--color4);
}

.faq-2 .faq-box .question-header .click i {
  color: var(--color4);
}

.faq-2 .faq-box .about-text {
  color: var(--color1);
  border-top: 2px solid var(--color6);
}

.single-services .video-presentation {
  margin-top: 30px;
}

/* :: Careers Page */
.item-careers {
  border: 1px solid var(--color9);
  border-radius: 2px;
  padding: 40px;
  margin-bottom: 30px;
}

.item-careers h4 a {
  display: block;
  color: var(--color4);
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: capitalize;
  position: relative;
}

.item-careers h4:hover a {
  color: var(--color2);
}

.item-careers ul li {
  display: inline-block;
  font-weight: 500;
  margin-right: 10px;
}

.item-careers ul li.active {
  background-color: var(--color2);
  padding: 2px 15px;
  color: var(--color3);
  border-radius: 3px;
}

.item-careers p {
  margin: 15px 0;
}

.item-careers a {
  font-size: 14px;
  color: var(--color2);
  display: inline-block;
  text-transform: capitalize;
  font-weight: 600;
}

/* :: Shop Page */
.shop-item {
  margin-bottom: 30px;
}

.shop-item .item-img {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}

.shop-item .item-img span {
  position: absolute;
  z-index: 1;
  display: block;
  width: 0px;
  height: 0px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 5px;
  opacity: 1;
  background-color: rgb(225 29 7 / 0.8);
}

.shop-item:hover .item-img span {
  width: 1000px;
  height: 1000px;
}

.shop-item .item-img .box-more a {
  padding: 0 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  background-color: var(--color3);
  color: var(--color2)
}

.shop-item:hover .item-img .box-more a {
  opacity: 1;
  visibility: visible;
}

.shop-item .item-text .open-item-shop h4 {
  margin: 16px 0 12px 0;
  line-height: 1;
  text-transform: capitalize;
  font-size: 26px;
  font-weight: 500;
  color: var(--color4);
}

.shop-item .item-text .open-item-shop h4:hover {
  color: var(--color2);
}

.shop-item .item-text span {
  color: var(--color2);
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
}

/* :: Single Product */
.shop-page .text-box-details .title-product {
  font-size: 45px;
  line-height: 1.1;
  color: var(--color4);
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 25px;
}

.shop-page .text-box-details .item-review span {
  display: inline-block;
  font-size: 14px;
  color: var(--color10);
}

.shop-page .text-box-details .item-review span i.active {
  color: var(--color2);
}

.shop-page .text-box-details .item-review span.reviews {
  margin-left: 40px;
}

.shop-page .text-box-details .item-review span.spance-span {
  margin: 0 5px;
}

.shop-page .text-box-details .item-review span a {
  font-size: 14px;
  color: var(--color10);
}

.shop-page .text-box-details .item-review span a:hover {
  color: var(--color2);
}

.shop-page .text-box-details .item-price {
  font-size: 18px;
  font-weight: 800;
  line-height: 1;
  color: var(--color2);
  margin-top: 15px;
  margin-bottom: 25px;
}

.shop-page .text-box-details .btn-1 {
  margin-bottom: 25px;
}

.shop-page .text-box-details .list-details li {
  line-height: 2;
  font-size: 16px;
}

.shop-page .text-box-details .list-details li span {
  font-weight: bold;
  color: var(--color4);
  min-width: 120px;
  display: inline-block;
}

.shop-page .text-box-details .list-details li a {
  color: var(--color4);
  margin-right: 20px;
}

.shop-page .text-box-details .list-details li a:hover {
  color: var(--color2);
}

.shop-page .description {
  margin-top: 50px;
  margin-bottom: 30px;
}

.shop-page .description ul li {
  margin-right: 5px;
  text-transform: capitalize;
  position: relative;
  text-align: center;
  z-index: 1;
  font-size: 14px;
  font-weight: 600;
  min-width: 180px;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
  letter-spacing: 1px;
  border-radius: 3px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: var(--color2);
  display: inline-block;
  cursor: pointer;
  color: var(--color3);
}

.shop-page .description ul li:hover {
  color: var(--color3);
}

.shop-page .description ul li:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--color4);
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: bottom center;
  -ms-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.shop-page .description ul li:hover:after {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
}

.shop-page .description p {
  margin: 25px 0 0 0;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.shop-page .description p.active {
  opacity: 1;
  visibility: visible;
  display: block;
}

/* :: Success Box */
.success-box {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.7);
}

.success-box-container {
  position: relative;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  background-color: var(--color12);
  color: var(--color3);
  text-align: center;
  border-radius: 5px;
}

.success-box-container h3 {
  width: 75%;
  margin: auto;
}

.success-box-container ul {
  margin: 15px auto;
}

.success-box-container li {
  margin: 5px auto;
  font-size: 17px;
}

/* :: 404 Error Page */
.page-404-area {
  padding: 150px 0;
}

.page-404-area h2 {
  font-size: 250px;
  color: var(--color4);
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 30px;
}

.page-404-area a {
  margin-top: 30px;
}

/* :: Single News Page */
/* :: Single Service Page */
.single-page-offer {
  color: var(--color3);
  position: relative;
  overflow: hidden;
  height: 85vh;
}

.single-page-offer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single-page-offer .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.single-page-offer h1 {
  color: var(--color3);
  font-size: 45px;
  font-weight: 700;
  line-height: 1.2;
}

.single-page-text i {
  color: var(--color3);
  font-size: 2rem;
  background-color: var(--color2);
  padding: 15px;
  border-radius: 5px;
}

.single-page-text span {
  font-size: 17px;
  font-weight: 700;
}

.single-page-text .btn-1:hover {
  color: var(--color3);
}

.single-page-text .btn-1:after {
  background-color: var(--color4) !important;
}

/*
=======================================
=======================================

RESPONSIVE 

=======================================
=======================================
*/

/* :: Large devices */
@media (max-width: 1199px) {

  .not-scroll {
    overflow: hidden !important;
  }

  /* :: Navbar */
  .nav-bar .nav-bar-link {
    display: none;
  }

  .nav-bar .box-content {
    position: relative;
    padding: 10px 0;
  }

  .nav-bar .lang {
    position: absolute;
    z-index: 1;
    left: 130px;
    top: 18px;
  }

  .nav-bar .box-content {
    display: block !important;
  }

  .nav-bar .logo {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav-bar .logo .open-nav-bar {
    cursor: pointer;
    position: relative;
  }

  .nav-bar .logo .open-nav-bar span {
    width: 30px;
    height: 3px;
    border-radius: 3px;
    background-color: var(--color3);
    display: block;
  }

  /* .nav-bar.active .logo .open-nav-bar span {
    background-color: var(--color4);
  } */

  .nav-bar .logo .open-nav-bar:hover span,
  .nav-bar .logo .open-nav-bar.active span {
    background-color: var(--color2);
  }

  .nav-bar .logo .open-nav-bar span:nth-of-type(2) {
    margin: 5px 0;
  }

  .nav-bar .logo .open-nav-bar.active span:nth-of-type(1) {
    position: absolute;
    top: 3px;
    left: 1px;
    transform: rotate(45deg);
  }

  .nav-bar .logo .open-nav-bar.active span:nth-of-type(2) {
    opacity: 0;
  }

  .nav-bar .logo .open-nav-bar.active span:nth-of-type(3) {
    position: absolute;
    bottom: 7px;
    left: 0;
    transform: rotate(-45deg);
  }

  .nav-bar .nav-bar-link.active {
    display: block;
    margin-top: 15px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    background-color: var(--color3);
  }

  .nav-bar .nav-bar-link .level-1 {
    flex-direction: column;
  }

  .nav-bar .nav-bar-link>ul>li {
    display: block;
  }

  .nav-bar.active .nav-bar-link ul li a,
  .menu-item-faq {
    color: var(--color4);
  }

  .nav-bar .nav-bar-link ul li a,
  .menu-item-faq {
    color: var(--color4);
    margin: 0;
    padding: 10px;
  }

  .nav-bar .nav-bar-link ul li a:before,
  .menu-item-faq:before {
    display: none
  }

  .nav-bar .nav-bar-link ul li a.active,
  .menu-item-faq.active {
    color: var(--color2);
  }

  .nav-bar .nav-bar-link>ul ul li a {
    font-weight: 500;
    width: 100%;
  }

  .nav-bar .nav-bar-link ul li.has-menu>a:after,
  .menu-item-faq:after {
    content: '\f107';
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    right: 10px;
    font-size: 12px;
    width: 25px;
    height: 25px;
    background-color: var(--color2);
    color: var(--color3);
    text-align: center;
    border-radius: 3px;
    font-weight: 900;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .nav-bar .nav-bar-link ul li.has-menu>a.active:after,
  .menu-item-faq.active:after {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .nav-bar .nav-bar-link>ul ul {
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
    opacity: unset;
    visibility: unset;
    position: unset;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: none;
  }

  .nav-bar .nav-bar-link>ul ul.active {
    display: block;
  }

  .nav-bar .info-nav {
    position: absolute;
    top: 11px;
    right: 50px;
  }

  .nav-bar.cover-white {
    background-color: var(--color3);
  }

  .nav-bar.cover-white .logo .logo-nav img.two {
    display: block !important;
  }

  .nav-bar.cover-white .logo .logo-nav img.one {
    display: none !important;
  }

  .nav-bar .logo .open-nav-bar span {
    background: var(--color2) !important;
  }

  .nav-bar.cover-white .logo .logo-nav span,
  .nav-bar.cover-white .info-nav .info-nav-contact .contact-nav a {
    color: var(--color4);
  }

  .nav-bar.cover-white .info-nav .info-nav-contact i {
    color: var(--color2);
  }

  .nav-bar.cover-white {
    border-bottom: 3px solid var(--color2);
  }

  /* :: Header */
  .header .banner .handline {
    font-size: 60px;
  }

  /* :: Provide */
  .provide .text-box {
    padding: 100px 40px
  }

  /* :: Custome */
  .sec-title h3 {
    font-size: 34px;
  }

  .breadcrumb-header .banner h1 {
    font-size: 62px;
  }

  .breadcrumb-header .banner p {
    font-size: 17px;
  }

  .expert .sec-title h3 {
    font-size: 40px;
  }

  .expert .statistic-slider .swiper-pagination {
    right: 0;
  }

  .quote-info .info-top .item h5 {
    font-size: 20px;
  }

  .quote-info .info-down a {
    font-size: 17px;
  }

  .provide .text-box .sec-title h3 {
    font-size: 40px;
  }

  .contact .sec-title h3 {
    font-size: 45px;
  }

  .expert .statistic-slider .statistic-item .counter {
    font-size: 45px;
  }

  .news-item .text-box h5 {
    font-size: 19px;
    line-height: 25px;
  }

  .testimonial-slider .text-box {
    font-size: 19px !important;
    line-height: 1.7 !important;
  }

  .item-work .hover-box .text-box h4 {
    font-size: 19px;
  }

}

/* :: Medium devices */
@media (max-width: 991px) {

  /* :: Section Title */
  .sec-title h3 {
    margin-bottom: 20px;
  }

  /* :: Navbar */
  /* .nav-bar .nav-bar-link {
    display: none;
  } */

  .nav-bar .info-nav {
    right: 35px;
  }

  .nav-bar .info-nav-contact {
    display: none;
  }



  /* :: Navbar Home 2 */
  .navs-home-2 .nav-top .nav-top-box ul li .btn-1 {
    display: none;
  }

  /* :: Header */
  .header .banner .handline {
    font-size: 60px;
  }

  /* :: Header Home 2 */
  .header .petrol-info {
    display: none !important;
  }

  /* :: About Us */
  .about-us .text-box {
    margin-top: 50px;
  }

  /* :: About Us Home 3 */
  .about-us-home-3 .text-box {
    margin: 0 0 50px 0;
  }

  .about-us-2-home-3 .text-box,
  .about-us-2-home-3 .skills {
    margin: 50px 0 0 0;
  }

  /* :: Provide */
  .provide .text-box .provide-features-box {
    margin-top: 30px;
  }

  /* :: Expert */
  .expert .sec-title h3 {
    font-size: 48px;
  }

  .expert .statistic-slider {
    margin-top: 50px;
    padding: 30px;
    padding-top: 0;
  }

  .expert .statistic-slider .statistic-item i {
    margin-bottom: 30px;
  }

  .expert .statistic-slider.owl-theme .owl-nav.disabled+.owl-dots {
    right: 20px;
  }

  /* :: Quote */
  .faq-quote .quote {
    margin-top: 50px;
  }

  .quote .quote-info .info-down {
    margin-top: 70px;
  }

  .quote .text-box {
    margin-top: 50px;
  }

  /* :: Testimonial */
  .testimonial .sec-title h3 {
    margin-bottom: 0;
  }

  /* :: Breadcrumb Header */
  .breadcrumb-header .banner h1 {
    font-size: 50px;
  }

  .single-services h3,
  .shop-page .text-box-details .title-product {
    font-size: 32px;
  }

  /* :: Single Product */
  .shop-page .text-box-details .title-product {
    margin-top: 50px;
  }

  .widget.ml-20,
  .single-services .single-services-list.mr-20,
  .dedicated-customer.mr-20,
  .brochures.mr-20,
  .single-team .team-box.mr-20 {
    margin: 0 0 30px 0;
  }

  .brochures,
  .dedicated-customer {
    padding: 30px;
  }

  .brochures a,
  .dedicated-customer .btn-1 {
    min-width: 100px;
  }

  .page-404-area h2 {
    font-size: 200px
  }

  .single-news-offer h1 {
    font-size: 40px;
  }
}

/* :: Small devices */
@media (max-width: 767px) {

  /* :: Section Title */
  .sec-title h3 {
    font-size: 38px;
  }

  .button-sec {
    display: block !important;
    margin-top: 25px;
  }

  /* :: Navbar Home 2 */
  .navs-home-2 .nav-top {
    display: none;
  }

  .nav-bar .nav-bar-link ul li a,
  .menu-item-faq {
    font-size: 16px;
    padding: 12px 20px;
  }

  /* :: Header */
  .header .banner .handline {
    font-size: 60px;
  }

  .header .swiper-slide .btn-2 {
    margin-left: 10px !important;
  }

  /* :: Provide */
  .provide .text-box {
    padding: 100px 120px;
  }

  .provide .text-box .sec-title h3 {
    font-size: 40px;
  }

  .provide .text-box .provide-features-box {
    margin-top: 0px;
  }

  .provide .img-box {
    height: 600px;
  }

  /* :: Contact */
  .contact .sec-title h3 {
    font-size: 50px;
  }

  /* :: Expert */
  .expert .statistic-slider {
    margin-top: 50px;
  }

  .expert .sec-title h3 {
    font-size: 40px;
  }

  /* :: Testimonial */
  .testimonial-slider .item-box .text-box,
  .testimonial-home-2 .testimonial-slider .testimonial-item .text-box {
    font-size: 22px;
    line-height: 1.3;
  }

  /* :: CopyRight */
  .copyright p {
    float: none;
    text-align: center;
    margin-bottom: 20px;
  }

  .copyright ul {
    float: none;
    text-align: center;
  }

  .item-work .hover-box .open-item-work {
    top: 0;
  }

  .item-work .hover-box .open-item-work:hover {
    background-color: var(--color4);
    color: var(--color3)
  }

  .item-work .hover-box .text-box .tags h5,
  .item-work .hover-box .text-box .tags p,
  .item-work .hover-box .text-box h4 {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    visibility: visible;
    opacity: 1;
  }

  .work .item-work span {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }

  .item-work .img-box img {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    -webkit-transition: all 2s ease-out;
    -o-transition: all 2s ease-out;
    transition: all 2s ease-out;
  }

  .faq .faq-box .question-header .click {
    font-size: 17px;
  }

  .single-news-offer h1 {
    font-size: 30px;
    line-height: 1.4;
  }

  .color-change i {
    display: none;
  }

  .copyright ul li {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .success-box-container {
    width: 90%;
    padding: 25px;
  }
}

/* :: Extra small devices */
@media (max-width: 575px) {

  /* :: Navbar */
  .nav-bar .info-nav {
    display: none !important;
  }

  /* :: Section Title */
  .sec-title h3 {
    font-size: 30px;
  }

  /* :: Menu Box */
  .menu-box .inner-menu {
    width: 100%;
  }

  /* :: Header */
  .header .banner .handline {
    font-size: 35px;
  }

  .header .banner a.btn-1:last-of-type {
    margin-top: 10px;
    margin-left: 0;
  }

  .header .swiper-slide .btn-1 {
    margin-right: 10px !important;
  }

  .header .swiper-slide .btn-2 {
    margin-left: auto !important;
  }

  /* :: Features Home 2 */
  .features-home-2 .features-item i {
    font-size: 48px;
  }

  .features-home-2 .features-item h4 {
    font-size: 20px;
  }

  /* :: About Us */
  .about-us .text-box ul li {
    float: none;
    width: 100%;
  }

  .about-us .img-box .about-img .experience-about {
    display: none;
  }

  /* :: About Us Home 3 */
  .about-us-home-3 .text-box .sign-about-us {
    display: block !important
  }

  .about-us-home-3 .text-box .sign-about-us img {
    margin-bottom: 18px;
  }

  .about-us-home-3 .img-box .about-img .mission {
    display: none;
  }

  /* :: Provide */
  .provide .text-box {
    padding: 100px 40px;
  }

  .provide .text-box .provide-features-box {
    margin-top: 30px;
  }

  .provide .text-box .sec-title h3 {
    font-size: 32px;
  }

  /* :: Provide Home 3 */
  .provide-features-home-3 .features-home-3-item {
    float: none;
    width: auto;
    padding-right: 30px;
  }

  .provide-features-home-3 .features-home-3-item:first-of-type {
    margin-bottom: 30px;
  }

  /* :: Expert */
  .expert .sec-title h3 {
    font-size: 34px;
  }

  /* :: Contact */
  .contact .sec-title h3 {
    font-size: 42px;
  }

  .page-404-area h2 {
    font-size: 120px
  }

  .single-news-offer h1 {
    font-size: 27px;
  }

  .provide .text-box .profile img:last-of-type {
    width: 160px;
    margin-left: 15px;
  }
}